<template>
  <!-- 预约成功页面 -->
  <div class="appointment-successful">
    <div class="success">
      <div class="success-svg">
        <svg
          style="font-size: 7rem"
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href='#icon-yycg'></use>
        </svg>
        <span>预约成功!</span>
      </div>
      <div class="success-text">
        <div>
          <span>就诊人:</span>
          <span>{{$route.query.name}}</span>
        </div>
        <div>
          <span>号源:</span>
          <span>{{$route.query.sort_no}}</span>
        </div>
        <div>
          <span>科室名称:</span>
          <span>{{$route.query.dept_name}}</span>
        </div>
        <div>
          <span>医生名称:</span>
          <span>{{$route.query.doc_name}}</span>
        </div>
        <div>
          <span>就诊时间:</span>
          <span>{{$route.query.date.slice(0,10)}}</span>
        </div>
        <div>
          <span>时间段:</span>
          <span>{{$route.query.time}}</span>
        </div>
      </div>
      <footer>
        <span>{{time + '秒钟后自动跳转到智能导诊'}}</span>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appointmentSuccessful',
  data () {
    return {
      time: 5
    }
  },
  mounted () {
    let stop = setInterval(() => {
      if (this.time > 0) {
        this.time--
      } else {
        clearInterval(stop)
        this.$router.push({
          path: '/questionnaire', query: {
            id: 2,
            title: '智能导诊'
          }
        })
      }
    }, 1000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(stop);
    })
  },
}
</script>

<style lang="less" scoped>
.appointment-successful {
  height: 100vh;
  .success {
    .success-svg {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 0;
      border-bottom: 1rem solid @lineColor;
    }
    .success-text {
      margin-top: 1rem;
      padding: 0 1rem;
      background-color: #fff;
      div {
        display: flex;
        line-height: 3rem;
        span:first-child {
          flex: 0.3;
        }
        span:last-child {
          flex: 0.7;
        }
      }
    }
    footer {
      text-align: center;
      span {
        line-height: 10rem;
      }
    }
  }
}
</style>